import { lazy } from "react";

import Paths from "./Paths";
// import Home from "../pages/Home";
// import SignIn from "../pages/SignIn";
// import SignUp from "../pages/SignUp";
// import {DeviceDashboard} from "../pages/Device/DeviceDashboard";
// import {DeviceFile} from "../pages/Device/DeviceFile";
// import { NotFound } from "../pages/NotFound";
// import { DevicePlaylists } from "../pages/Device/DevicePlaylists";
// import { Agency } from "../pages/Agency";
// import { NodeDashboard, NodeTV } from "../pages/Node";
// import { DeviceBroadcasts, DeviceStreamer } from "../pages/Device";
// import { MyAccount } from "../pages/Account";
// import ChildAgency from "../pages/Agency/ChildAgency/ChildAgency";
// import UserAgency from "../pages/Agency/UserAgency/UserAgency";
// import NodeLive from "../pages/Node/NodeLive/NodeLive";
// import NodeStreamer from "../pages/Node/NodeStreamer/NodeStreamer";
// import NodeBroadcasts from "../pages/Node/NodeBroadcasts/NodeBroadcasts";
// import NodeInfo from "../pages/Node/NodeInfo/NodeInfo";
// import { PublicHomePage } from "../pages/Home/PublicHomePage";
// import { Livestream } from "../pages/Livestream";

const NotFoundLazy = lazy(() => import('../pages/NotFound/NotFound'));

const HomeLazy = lazy(() => import('../pages/Home'));
const SignInLazy = lazy(() => import('../pages/SignIn'));
const SignUpLazy = lazy(() => import('../pages/SignUp'));
const MyAccountLazy = lazy(() => import('../pages/Account/MyAccount'));
const PublicHomePageLazy = lazy(() => import('../pages/Home/PublicHomePage/PublicHomePage'));
const LivestreamLazy = lazy(() => import('../pages/Livestream/Livestream'));

const DeviceDashboardLazy = lazy(() => import('../pages/Device/DeviceDashboard/DeviceDashboard'));
const DeviceFileLazy = lazy(() => import('../pages/Device/DeviceFile/DeviceFile'));
const DevicePlaylistsLazy = lazy(() => import('../pages/Device/DevicePlaylists/DevicePlaylists'));
const DeviceLiveLazy = lazy(() => import('../pages/Device/DeviceLive/DeviceLive'));
const DeviceBroadcastsLazy = lazy(() => import('../pages/Device/DeviceBroadcasts/DeviceBroadcasts'));
const DeviceStreamerLazy = lazy(() => import('../pages/Device/DeviceStreamer/DeviceStreamer'));

const NodeDashboardLazy = lazy(() => import('../pages/Node/NodeDashboard/NodeDashboard'));
const NodeTVLazy = lazy(() => import('../pages/Node/NodeTV/NodeTV'));
const NodeLiveLazy = lazy(() => import('../pages/Node/NodeLive/NodeLive'));
const NodeStreamerLazy = lazy(() => import('../pages/Node/NodeStreamer/NodeStreamer'));
const NodeBroadcastsLazy = lazy(() => import('../pages/Node/NodeBroadcasts/NodeBroadcasts'));
const NodeInfoLazy = lazy(() => import('../pages/Node/NodeInfo/NodeInfo'));
const NodeSettingsLazy = lazy(() => import('../pages/Node/NodeSettings/NodeSettings'));

const ChildAgencyLazy = lazy(() => import('../pages/Agency/ChildAgency/ChildAgency'));
const UserAgencyLazy = lazy(() => import('../pages/Agency/UserAgency/UserAgency'));
const AgencyLazy = lazy(() => import('../pages/Agency/Agency'));

const RoutesConfig = [
  {
    path: Paths.NOTFOUND,
    element: NotFoundLazy,
  },

  {
    path: Paths.HOME,
    element: HomeLazy,
  },
  {
    path: Paths.LOGIN,
    element: SignInLazy,
  },
  {
    path: Paths.SIGNUP,
    element: SignUpLazy,
  },
  {
    path: Paths.MY_ACCOUNT,
    element: MyAccountLazy,
  },
  {
    path: Paths.MAP,
    element: PublicHomePageLazy,
  },
  {
    path: Paths.LIVESTREAM,
    element: LivestreamLazy,
  },


  {
    path: Paths.DEVICE_DASHBOARD,
    element: DeviceDashboardLazy,
  },
  {
    path: Paths.DEVICE_FILE,
    element: DeviceFileLazy,
  },
  {
    path: Paths.DEVICE_PLAYLISTS,
    element: DevicePlaylistsLazy,
  },
  {
    path: Paths.DEVICE_LIVE,
    element: DeviceLiveLazy,
  },
  {
    path: Paths.DEVICE_BROADCASTS,
    element: DeviceBroadcastsLazy,
  },
  {
    path: Paths.DEVICE_STREAMER,
    element: DeviceStreamerLazy,
  },


  {
    path: Paths.NODE_DASHBOARD,
    element: NodeDashboardLazy,
  },
  {
    path: Paths.NODE_TV,
    element: NodeTVLazy,
  },
  {
    path: Paths.NODE_LIVE,
    element: NodeLiveLazy,
  },
  {
    path: Paths.NODE_STREAMER,
    element: NodeStreamerLazy,
  },
  {
    path: Paths.NODE_BROADCASTS,
    element: NodeBroadcastsLazy,
  },
  {
    path: Paths.NODE_INFO,
    element: NodeInfoLazy,
  },
  {
    path: Paths.NODE_SETTING,
    element: NodeSettingsLazy,
  },


  {
    path: Paths.CHILD_AGENCY_HOME,
    element: ChildAgencyLazy,
  },
  {
    path: Paths.CHILD_AGENCY_USERS,
    element: UserAgencyLazy,
  },
  {
    path: Paths.AGENCY,
    element: AgencyLazy,
  }
];

export default RoutesConfig;