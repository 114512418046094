import DefaultFavIcon from "./Assets/logo.png";
import DefaultLogoMedium from "./Assets/logo.png";
import VNMapFavIcon from "./Assets/vnmap.favicon.ico.png";
import VNMapLogoMedium from "./Assets/vnmap.logo.png";
import BsmartCityFavIcon from "./Assets/bsmart.favicon.png";
import BsmartCityLogoMedium from "./Assets/bsmart.logo.png";

export const BRAND_BY_DOMAIN = {
  "default": {
    favicon: DefaultFavIcon,
    fullTitle: "iNut - Hệ sinh thái IoT",
    fullTitleUp: "INUT - Hệ sinh thái IoT",
    shortTitle: "iNut",
    shortTitleUp: "INUT",
    logoMedium: DefaultLogoMedium,
  },

  "truyenthanh.vnmap3d.com": {
    favicon: VNMapFavIcon,
    fullTitle: "VNMap - Truyền thanh thông minh",
    fullTitleUp: "VNMAP - TRUYỀN THANH THÔNG MINH",
    shortTitle: "VNMap",
    shortTitleUp: "VNMAP",
    logoMedium: VNMapLogoMedium,
  },

  "speaker.bsmart.city": {
    favicon: BsmartCityFavIcon,
    fullTitle: "iSpeaker - Truyền thanh thông minh",
    fullTitleUp: "iSPEAKER - TRUYỀN THANH THÔNG MINH",
    shortTitle: "iSpeaker",
    shortTitleUp: "iSPEAKER",
    logoMedium: BsmartCityLogoMedium,
  }
}

export const AppLivestreamRecorderConfig = {
  recordingGain : 1, // Initial recording volume
  encoderBitRate : 64, // MP3 encoding bit rate
  streaming : true, // Data will be returned in chunks (ondataavailable callback) as it is encoded,
                    // rather than at the end as one large blob
  streamBufferSize : 1024, // Size of encoded mp3 data chunks returned by ondataavailable, if streaming is enabled
  constraints : { // Optional audio constraints, see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
    channelCount : 1, // Set to 2 to hint for stereo if it's available, or leave as 1 to force mono at all times
    // autoGainControl : true,
    echoCancellation : true,
    noiseSuppression : true
  },

  // Used for debugging only. Force using the older script processor instead of AudioWorklet.
  // forceScriptProcessor: true,
}