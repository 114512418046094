const Paths = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  AGENCY: "/agency",
  MY_ACCOUNT: "/me",
  MAP: "/map",
  LIVESTREAM: "/live",

  DEVICE_DASHBOARD: "/device/:device_id",
  DEVICE_FILE: "/device/:device_id/files",
  DEVICE_PLAYLISTS: "/device/:device_id/playlists",
  DEVICE_LIVE: "/device/:device_id/live",
  DEVICE_BROADCASTS: "/device/:device_id/broadcasts",
  DEVICE_STREAMER: "/device/:device_id/streamer",

  NODE_DASHBOARD: "/node/:node_id",
  NODE_TV: "/node/:node_id/tv",
  NODE_LIVE: "/node/:node_id/live",
  NODE_STREAMER: "/node/:node_id/streamer",
  NODE_BROADCASTS: "/node/:node_id/broadcasts",
  NODE_INFO: "/node/:node_id/info",
  NODE_SETTING: "/node/:node_id/settings",

  CHILD_AGENCY_HOME: "/agency/:agency_id",
  CHILD_AGENCY_USERS: "/agency/:agency_id/users",

  NOTFOUND: "*",
}

export default Paths;